import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatFormFieldModule, MatInputModule } from '@angular/material';
import { AvatarDialogComponent } from '../avatar-dialog/avatar-dialog.component';
import { FirebaseService } from '../firebase.service';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { FirebaseUserModel } from '../core/user.model';
import { Location } from '@angular/common';
import { UserResolver } from '../user/user.resolver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { map, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
export class AppModule { }

@Component({
  selector: 'app-admin0020',
  templateUrl: './admin0020.component.html',
  styleUrls: ['./admin0020.component.scss']
})
export class Admin0020Component implements OnInit {
  
  user: FirebaseUserModel = new FirebaseUserModel();
  exampleForm: FormGroup;
  items: [];
  photos: any;
  filelink: [];
  image: any;
  reportedIssues: [];
  // status: any;
  uploadState: Observable<string>;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;

  validation_messages = {
  //  'name': [
  //    { type: 'required', message: 'Name is required.' }
  //  ],
  //  'surname': [
  //    { type: 'required', message: 'Surname is required.' }
  //  ],
  //  'status': [
  //    { type: 'required', message: 'Age is required.' },
  //  ]
 };
  item: any;
  

 

  constructor(
    public firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private location : Location,
    public userService: UserService,
    public authService: AuthService,
    // public afAuth: AngularFireAuth
    // tslint:disable-next-line: no-shadowed-variable
    public UserResolver: UserResolver,
    private afStorage: AngularFireStorage
  ) { }

  upload = (event) => {
    // create a random id
    const randomId = Math.random().toString(36).substring(2);
    // create a reference to the storage bucket location
    this.ref = this.afStorage.ref('/images/' + randomId);
    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    this.task = this.ref.put(event.target.files[0]);
    
    // AngularFireUploadTask provides observable
    // to get uploadProgress value
    // this.uploadProgress = this.task.snapshotChanges()
    // .pipe(map(s => (s.bytesTransferred / s.totalBytes) * 100));
    
    // observe upload progress
    this.uploadProgress = this.task.percentageChanges();
    this.task.snapshotChanges().pipe(
      finalize(() => this.downloadURL = this.ref.getDownloadURL())
    )
    .subscribe();
    this.uploadState = this.task.snapshotChanges().pipe(map(s => s.state));
    console.log (this.downloadURL)
  }


  // upload(event) {
  //   const id = Math.random().toString(36).substring(2);
  //   this.ref = this.afStorage.ref(id);
  //   this.task = this.ref.put(event.target.files[0]);
  //   this.uploadState = this.task.snapshotChanges().pipe(map(s => s.state));
  //   this.uploadProgress = this.task.percentageChanges();
  //   this.downloadURL = this.task.downloadURL();
  // }
  ngOnInit() {
    // this.downloadURL = this.afStorage.ref('/images/my_file_name.png').getDownloadURL();
    const cancelCe = "";
    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
        this.createForm();
      }
    })
  }

  createForm() {
    this.exampleForm = this.fb.group({
  //  name: [this.item.name],
  //     surname: [this.item.surname],
      status: [this.item.status],
      brand: [this.item.brand],
      consumables: [this.item.consumables],
      consumablesCost: [this.item.consumablesCost],
      datePosted: [this.item.datePosted],
      reportedIssues: [this.item.reportedIssues],
      manufacturer: [this.item.manufacturer],
      photos: [this.item.photos],
      storeName: [this.item.storeName],
      poNumber: [this.item.poNumber],
      userId: [this.item.userId],
      userEmail: [this.item.userEmail],
      ceNumber: [this.item.ceNumber],
      ceAmount: [this.item.ceAmount],
      rejection_reason:[this.item.rejection_reason],
      priority: [this.item.priority],
      uploadSrc:[this.item.uploadSrc],
      downloadURL:[this.item.downloadURL],
      filelink:[this.item.filelink],
      url:[this.item.url],
      invoiceMonth:[this.item.invoiceMonth],
      PO:[this.item.PO],
      CE:[this.item.CE]
    });
  }


  openDialog() {
    const dialogRef = this.dialog.open(AvatarDialogComponent, {
      height: '400px',
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.item.avatar = result.link;
      }
    });
  }

  onSubmit(value){
    // value.avatar = this.item.avatar;
    value.status = String(value.status);
    this.firebaseService.updateUser(this.item.id, value)
    .then(
      res => {
        this.router.navigate(['/home']);
      }
    )
  }

  delete(){
    this.firebaseService.deleteUser(this.item.id)
    .then(
      res => {
        this.router.navigate(['/home']);
      },
      err => {
        console.log(err);
      }
    )
  }

  cancel(){
    this.router.navigate(['/home']);
  }
  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.location.back();
    }, (error) => {
      console.log("Logout error", error);
    });
  }

}
