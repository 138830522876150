import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Admin0020RoutingModule } from './admin0020-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Admin0020RoutingModule
  ]
})
export class Admin0020Module { }
