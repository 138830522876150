import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatFormFieldModule, MatInputModule } from '@angular/material';
import { AvatarDialogComponent } from '../avatar-dialog/avatar-dialog.component';
import { FirebaseService } from '../firebase.service';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { FirebaseUserModel } from '../core/user.model';
import { Location } from '@angular/common';
import { UserResolver } from '../user/user.resolver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
export class AppModule { }
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  user: FirebaseUserModel = new FirebaseUserModel();
  exampleForm: FormGroup;
  items: [];
  photos: any;
  reportedIssues: [];

  validation_messages = {
  //  'name': [
  //    { type: 'required', message: 'Name is required.' }
  //  ],
  //  'surname': [
  //    { type: 'required', message: 'Surname is required.' }
  //  ],
   'status': [
     { type: 'required', message: 'Age is required.' },
   ]
 };
  item: any;

  constructor(
    public firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private location : Location,
    public userService: UserService,
    public authService: AuthService,
    // public afAuth: AngularFireAuth
    // tslint:disable-next-line: no-shadowed-variable
    public UserResolver: UserResolver,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
        this.createForm();
      }
    })
  }

  createForm() {
    this.exampleForm = this.fb.group({
  //  name: [this.item.name],
  //     surname: [this.item.surname],
      status: [this.item.status],
      brand: [this.item.brand],
      consumables: [this.item.consumables],
      consumablesCost: [this.item.consumablesCost],
      datePosted: [this.item.datePosted],
      reportedIssues: [this.item.reportedIssues],
      manufacturer: [this.item.manufacturer],
      photos: [this.item.photos],
      storeName: [this.item.storeName],
      poNumber: [this.item.poNumber],
      userId: [this.item.userId],
      userEmail: [this.item.userEmail],
      ceNumber: [this.item.ceNumber],
      ceAmount: [this.item.ceAmount]
    });
  }


  openDialog() {
    const dialogRef = this.dialog.open(AvatarDialogComponent, {
      height: '400px',
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.item.avatar = result.link;
      }
    });
  }

  onSubmit(value){
    // value.avatar = this.item.avatar;
    value.status = String(value.status);
    this.firebaseService.updateUser(this.item.id, value)
    .then(
      res => {
        this.router.navigate(['/home']);
      }
    )
  }

  delete(){
    this.firebaseService.deleteUser(this.item.id)
    .then(
      res => {
        this.router.navigate(['/home']);
      },
      err => {
        console.log(err);
      }
    )
  }

  cancel(){
    this.router.navigate(['/home']);
  }
  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.location.back();
    }, (error) => {
      console.log("Logout error", error);
    });
  }
}
