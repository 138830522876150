<nav class="navbar navbar-default navbar-static-top navbar-inverse">
  <div class="container">
    <a (click)="logout()" class="navbar-text navbar-right navbar-link">Logout</a>
  </div>
</nav>
<h1 class="header">Logged User Page</h1>
<div class="row">
  <div class="col-md-4 col-md-offset-4">
    <div class="card" style="width: 20rem;">
      <img class="card-img-top image" [src]="user.image" *ngIf="user.image" alt="User image">
      <img class="card-img-top image" src="user.photoURL" *ngIf="!user.image" alt="User image">
      <div *ngIf='user.provider != "password"'class="card-body">
        <h4 class="card-title">{{user.name}}</h4>
        <p class="card-text"> </p>
      </div>
      <div *ngIf='user.provider == "password"'class="card-body">
        <form [formGroup]="profileForm">
          <div class="form-group">
            <label>Company Name</label>
            <input type="text" formControlName="name" class="form-control" required>
          </div>
        </form>
        <a (click)="save(profileForm.value)" class="btn btn-primary">Save</a>
      </div>
    </div>
  </div>
</div>
