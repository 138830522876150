<div class="col-md-12" style="background: #fff; width: 100%; height: 100%; padding: 0; height: 100vh;; overflow: hidden;">
  <div class="col-md-8">
      
    <div class="col-md-12">
        <div class="row">
            <!-- <div class="brand"> <a class="navbar-brand" href="#">
                <img alt="Brand" src="img/logo.png">
              </a></div> -->
              <!-- <div class="round-o-3 round-color-e"><span class="glyphicon glyphicon-off" aria-hidden="true"></span></div> -->
              
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
           
            
            <div class="col-md-12" style="margin-top: 50px;">
                <div class="brand" style="    margin-left: 20px;"> <a class="navbar-brand" href="#">
                  <img alt="Brand" src="../../assets/img/logo5x.png">
                  </a>
                  <br> <br>
                  <br>
                </div>
            <br><br>
        </div>
        </div>
  
       
        <form role="form" [formGroup]="loginForm">
        <div class="auth-container">
        <div class="col-md-12 col-sm-12">
            <h3 class="light">USERNAME / EMAIL ADDRRESS</h3>
            <input class="auth" type="email" formControlName="email"/>
        </div>
  
  
  
        <div class="col-md-12 col-sm-12" >
            <h3 class="light">PASSWORD</h3>
            <input class="auth" type="password" formControlName="password" />
            <label class="error">{{errorMessage}}</label>
     <label class="success">{{successMessage}}</label>
            
        </div>
    
        <button type="submit" (click)="tryLogin(loginForm.value)" style="color: #555" class="col-md-2  btn submit">Login</button>
        
      
        <div class="col-md-3 help"><p><a href="mailto:support@visibleworx.com">Need help logging in?</a></p></div>
    </div>
  </form>
  
    </div>
  </div>
  
  <div class="col-md-4" style="background: #A8D576; height: 100vh; overflow: none;">
    <!-- <img src="https://images.unsplash.com/photo-1572299769576-a10d0d022b7f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=80"/>
   -->
  </div>
  </div>