
<!-- <div class="search-box-area">
    <div id="search" class="fade">
      <div (click)="logout()" class="round-o-3 round-color-e"><span class="glyphicon glyphicon-off" aria-hidden="true"></span></div>


        <ul id="searchbox">
            Menu
            <li>VMS Dashboard</li>
            <li>Support</li>
            <li>Log Out</li>
        </ul>
    </div>
</div> -->

    <!-- Content -->
    
    <div class="col-md-12" style="margin-bottom: -30px !important;">
      <div class="content">
          <div class="col-md-12">
              <div class="row">
                  <div class="brand"> <a class="navbar-brand" href="#">
                    <img alt="Brand" src="../../assets/img/logo5x.png">
                    </a></div>
                    <div (click)="logout()" class="round-o-3 round-color-e"><span class="glyphicon glyphicon-off" aria-hidden="true"></span></div>
            
                  <br>
                  <br>
                  <br>
                  <div class="col-md-12" style="margin-top: 65px;">
                  <!-- <h1>{{user.name}}'s</h1>
                  <h1>Call Out Details</h1> -->

                  <br><br>
              </div>
              </div>
          </div>


  <!-- Request Card -->
  <div class="col-md-6 col-sm-12">
      <div class="row card-max">
          <div class="card-content">
              <br>
              <h1 class="light">{{item.storeName}}</h1>
              <p class="light address">{{item.storeAddress}}</p>
              <!-- <br> -->
              <div>
              <h3 class="light">REPORTED BY</h3>
              <p class="light">{{item.userEmail}}<br><span class="address">{{item.datePosted | date : "d MMM, y"}}</span></p>
              <hr>
              <h3 class="light">BRAND</h3>
              <p  class="light">{{item.manufacturer}} {{item.brand}}</p>
              <h3 class="light">ISSUES</h3>
              <ul class="light" style="list-style: none; padding: 0;">
                <div class="col-md-12" style="padding: 0;">
                  <li><p>{{item.OtherIssues}}</p></li>
                <li><p>{{item.reportedIssues[0]}}</p></li>
                <li><p>{{item.reportedIssues[1]}}</p></li>
                <li><p>{{item.reportedIssues[3]}}</p></li>
                <li><p>{{item.reportedIssues[4]}}</p></li>
              </div>

              <div class="col-md-12" style="padding: 0;">
                <li><p>{{item.reportedIssues[5]}}</p></li>
                <li><p>{{item.reportedIssues[6]}}</p></li>
                <li><p>{{item.reportedIssues[7]}}</p></li>
                <li><p>{{item.reportedIssues[8]}}</p></li>
              </div>
              </ul>
            </div>
              <hr>
          <div class="col-md-6" style="padding: 0;">
              <h3 *ngIf="item.status !== 'null'" class="light">STATUS</h3>
              <p *ngIf="item.status !== 'null'" class="light">{{item.status}}</p>
          </div>

          <div class="col-md-12" style="padding: 0; margin-bottom: 20px; color: #bbb !important; letter-spacing: 0.5px;">
            <h3 style="line-height: 120%;" class="light">INSTRUCTIONS / COMMENTS /<br>REASON FOR REJECTION </h3>
            <p class="">{{item.rejection_reason}}</p>
        </div>

          
          <div class="col-md-6" style="padding: 0;">
            <a href="{{item.PO}}" target="_blank"> <h3 class="light">PO NUMBER</h3></a>
              <p  class="light">{{item.poNumber}}</p>
              
          </div>
          <div class="col-md-6" style="padding: 0 ;">
            <a href="{{item.CE}}" target="_blank"><h3 class="light">CE NUMBER</h3></a>
          <p class="light">{{item.ceNumber}}</p>
          </div>
          


          <div class="col-md-6" style="margin: 0 !important; padding: 0 !important">
              <h3 class="light">CE AMOUNT</h3>
              <h2 class="light"><span *ngIf="item.ceAmount">R </span>{{item.ceAmount}}</h2>
              <br><br>
              <!-- <div class="col-md-6">
                <button *ngIf="item.PO" class="submit"><a href="{{item.PO}}">Download PO</a></button>
               </div> -->
              
          </div>
             
          </div>
      </div>
      <form class="edit-form" [formGroup]="exampleForm" novalidate (ngSubmit)="onSubmit(exampleForm.value)">
        
      <!-- Form - Purchase Order-->
      <div class="form-group"> 
      <div class="row card-max" style="margin: 25px 0;">
          <div class="card-content-min">
            <br>
            <h1 class="light">Purchase Orders</h1>
            <br>
              <div class="col-md-6 col-sm-12" style="padding: 0;">
                  <h3 class="light">PO NUMBER</h3>
                  <input type="text" class="edit-ce" formControlName="poNumber">
                  <br><br> 
                  <h3 style="line-height: 120%; margin-top: 31px;" class="light">PRIORITY LEVEL</h3>
                  <input style="display: none"class="form-control" formControlName="priority"> 
                  <input name="priority" type="radio" value="High Priority" formControlName="priority"/> <span class="light">&nbsp;  High Priority &nbsp;  </span><br>
                  <input name="priority" type="radio" value="Low Priority" formControlName="priority"/> <span class="light">&nbsp; Low Priority</span>
                  
              </div>
              <div class="col-md-6 col-sm-12">
                  <h3 class="light">APPROVE/ REJECT CE</h3>
                  <input style="display: none"class="form-control" formControlName="status"> <br>
                  <input name="status" type="radio" value="Approved" formControlName="status"/> <span class="light">&nbsp;  Approve &nbsp;  </span>
                  <input name="status" type="radio" value="Rejected" formControlName="status"/> <span class="light">&nbsp; Reject</span><br>
                  <input name="status" type="radio" value="Completed" formControlName="status"/> <span class="light"> &nbsp; Completed</span>
                  <br><br>
                  <span>
                   <h3 style="line-height: 120%;" class="light">Billing Month</h3>
                   <!-- <input type="text" class="edit-ce" name="invoiceMonth" formControlName="invoiceMonth"/> -->
                   <input type="month" id="invoiceMonth" name="invoiceMonth" formControlName="invoiceMonth"/>
                   </span>
             <br><br>
             <span>
              <h3 style="line-height: 120%;" class="light">INSTRUCTIONS / COMMENTS /<br>REASON FOR REJECTION </h3>
              <input type="text" class="edit-ce" name="rejection_reason" formControlName="rejection_reason"/>
              </span>

            
                </div>
                <button type="button" class="btn btn-default" style="margin: 0 !important; text-decoration: none !important;"  style="margin: 0 !important;" *ngIf="item.PO" ><a href="{{item.PO}}" target="_blank">Download PO</a></button>
          </div>
          
      </div>

       <!-- Form Upload PO -->
       <div class="row card-max" style="margin: 25px 0; padding: 25px 0;" *ngIf="!item.PO">
        <div class="card-content-min">
            <div class="col-md-12 col-sm-12" style="padding: 0;">
                <h3 class="light">Upload Purchase Order</h3>
                <br>
               
                <input size="60" type="file" (change)="upload($event)" accept=".pdf, .png,.jpg" multiple/>
                <br>
                <progress class="col-md-12" max="100" [value]="(uploadProgress | async)"></progress>
                <div>
                    <input style="display: none"class="form-control" formControlName="PO">
                    <!-- <span>
                    <p *ngIf="uploadState | async; let state">Are You Sure You Want To Upload This PO</p>
                  </span> -->
                   <!-- <input *ngIf="uploadState | async; let state" name="NPO" type="checkbox"/><span *ngIf="uploadState | async; let state" class="light">&nbsp; &nbsp; Cancel Upload &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;</span>
                    <input *ngIf="uploadState | async; let state" id="po_upl" name="PO" type="checkbox" [value]="downloadURL | async"  formControlName="PO"/><span *ngIf="uploadState | async; let state" class="light">&nbsp; &nbsp; Finalise Upload</span>
                  
                    <br> -->
                    <span *ngIf="uploadState | async; let state">
                      <label>Are You Sure You Want To Upload This PO?</label><br> 
                    <select  formControlName="PO">
                      <option disabled selected value> -- Select An Option -- </option>
                      <option  [value]="">No, Cancel Upload</option>
                      <option [value]="downloadURL | async"  >Yes, Finalise Upload</option>
                      
                    </select>
                  </span>
                    <br>
                </div>
                <section style="display: none;" class="control-bar" *ngIf="uploadState | async; let state">
                  <button (click)="task.pause()" [disabled]="state === 'paused'">Pause</button>
                  <button (click)="task.cancel()" [disabled]="!(state === 'paused' || state === 'running')">Cancel</button>
                  <button (click)="task.resume()" [disabled]="state === 'running'">Resume</button>
                </section>
                <br>
                <!-- <div *ngIf="downloadURL | async; let url">
                  <a href="{{url}}" target="_blank">Preview</a>
                </div> -->
            </div>
        </div>
        </div> 


      <!-- Form - Cost Estimate -->
      <div class="row card-max" style="margin: 25px 0;">
        <div class="card-content-min">
          <br>
          <h1 class="light">Cost Estimates</h1>
          <br>
            <div class="col-md-6 col-sm-12" style="padding: 0;">
                <h3 class="light">CE NUMBER</h3>
                <input class="edit-ce" type="text" formControlName="ceNumber">
            </div>

            <div class="col-md-6 col-sm-12" >
                <h3 class="light">CE AMOUNT</h3>
                <input class="edit-ce" type="text" formControlName="ceAmount">
                <br>
            </div>
            <button  type="button" class="btn btn-default" style="margin: 0 !important; text-decoration: none !important;" *ngIf="item.CE"><a href="{{item.CE}}" target="_blank">Download CE</a></button>
            <br>
        </div> 
        </div> 
      

     
     


           <!-- Form Upload CE-->
      <div class="row card-max" style="margin: 25px 0; padding: 25px 0;" *ngIf="!item.CE">
        <div class="card-content-min">
            <div class="col-md-12 col-sm-12" style="padding: 0;">
                <h3 class="light">Upload Cost Estimate</h3>
                <br>
               
                <input size="60" type="file" (change)="upload($event)" accept=".pdf, .png,.jpg" multiple/>
                <br>
                <progress class="col-md-12" max="100" [value]="(uploadProgress | async)"></progress>
                <div>
                    <input style="display: none"class="form-control" formControlName="CE"> 
                    <p *ngIf="uploadState | async; let state" style="margin-bottom: 5px;">Are You Sure You Want To Upload This CE</p>
                    <!-- <input *ngIf="uploadState | async; let state" id="ce_upl" name="NCE" type="checkbox"/><span *ngIf="uploadState | async; let state" class="light">&nbsp; &nbsp; Cancel Upload &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;</span>
                    <input *ngIf="uploadState | async; let state" id="ce_upl" name="CE" type="checkbox" [value]="downloadURL | async"  formControlName="CE"/><span *ngIf="uploadState | async; let state" class="light">&nbsp; &nbsp; Finalise Upload</span>
                    <br> -->
                    <select *ngIf="uploadState | async; let state" formControlName="CE">
                      <option disabled selected value> -- Select An Option -- </option>
                      <option [value]="downloadURL | async"  >Yes, Finalise Upload</option>
                      <option [value]="">No, Cancel Upload</option>
                    </select>
                    <br>
                </div>
                
                <section style="display: none;" class="control-bar" *ngIf="uploadState | async; let state">
                  <button (click)="task.pause()" [disabled]="state === 'paused'">Pause</button>
                  <button (click)="task.cancel()" [disabled]="!(state === 'paused' || state === 'running')">Cancel</button>
                  <button (click)="task.resume()" [disabled]="state === 'running'">Resume</button>
                </section>
                <!-- <button onclick="finishuploadce()">Upload CE</button> -->
                <!-- <div class="container" *ngIf="downloadURL | async; let url">
                    <img [src]="url" alt="Image from AngularFireStorage">
                </div> -->
            </div>
        </div>
        </div> 


      <button class="col-md-5 submit" type="submit" [disabled]="!exampleForm.valid">Submit</button>
      <!-- <button (click)="deleteUser()" class="col-md-5 submit" type="submit" [disabled]="!exampleForm.valid">Delete</button>
    -->
      <br>
  </div>
</form>
</div>

  <!-- //Request Card -->

  <!-- Image Card -->
  <div class="col-md-6 col-sm-12">
      <div class="row card-max" style="margin: 0 0 20px 0 !important;">
          <div class="card-content">
              <hr>
              <div class="thumbnail" *ngIf="!image">
                <img class="responsive media-object" [src]="item.image" onerror="this.style.display='none'">
        </div>

              <div class="thumbnail">
                  <img class="responsive media-object" [src]="item.photos[0]" onerror="this.style.display='none'">
          </div>
          <!-- <hr> -->

          <div class="thumbnail" >
            <img class="responsive media-object" [src]="item.photos[1]" onerror="this.style.display='none'">
      </div>
      <!-- <hr> -->

      <div class="thumbnail">
        <img class="responsive media-object" [src]="item.photos[2]" onerror="this.style.display='none'">
  </div>
  <!-- <hr> -->

  <div class="thumbnail">
    <img class="responsive media-object" [src]="item.photos[3]" onerror="this.style.display='none'">
</div>

<!-- <hr> -->

<div class="thumbnail">
<img class="responsive media-object" [src]="item.photos[4]" onerror="this.style.display='none'">
</div>

<!-- <hr> -->

<div class="thumbnail">
<img class="responsive media-object" [src]="item.photos[5]" onerror="this.style.display='none'">
</div>

<!-- <hr> -->

<div class="thumbnail">
<img class="responsive media-object" [src]="item.photos[6]" onerror="this.style.display='none'">
</div>
<hr>
<!-- 
<div>
  <label for="file">File:</label>
<input type="file" (change)="upload($event)" accept=".png,.jpg" />

<progress max="100" [value]="(uploadProgress | async)"></progress>
<div class="result" *ngIf="downloadURL | async; let uploadSrc">

  <input type="text" class="edit-ce" ng-model="uploadSrc">
    <a [href]="uploadSrc">You just uploaded this file!</a>
    
</div>

<section class="control-bar" *ngIf="uploadState | async; let state">
  <button (click)="task.pause()" [disabled]="state === 'paused'">Pause</button>
  <button (click)="task.cancel()" [disabled]="!(state === 'paused' || state === 'running')">Cancel</button>
  <button (click)="task.resume()" [disabled]="state === 'running'">Resume</button>
</section>

<div class="container" *ngIf="downloadURL | async; let url">
    <img [src]="url" alt="Image from AngularFireStorage">
</div>
</div> -->

<!-- <script>
  function check() {
    document.getElementById("red").checked = true;
  }
  function uncheck() {
    document.getElementById("red").checked = false;
  }
  </script> -->
