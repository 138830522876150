import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(public db: AngularFirestore) {}

  getAvatars(){
      return this.db.collection('/avatar').valueChanges()
  }

  getUser(userKey){
    
    return this.db.collection('vworx_requests', ref => ref.orderBy('datePosted', 'desc').limit(5)).doc(userKey).snapshotChanges();
  }

  updateUser(userKey, value){
    value.status = value.status;
    return this.db.collection('vworx_requests').doc(userKey).set(value);
  }

  deleteUser(userKey){
    return this.db.collection('vworx_requests').doc(userKey).delete();
  }

  getUsers(){
    return this.db.collection('vworx_requests', ref => ref.orderBy('datePosted', 'desc')).snapshotChanges();
    }

  searchUsers(searchValue){
    return this.db.collection('vworx_requests',ref => ref.where('storeName', '>=', searchValue)
      .where('storeName', '<=', searchValue + '\uf8ff'))
      .snapshotChanges()
  }

  searchUsersByAge(value){
    return this.db.collection('vworx_requests',ref => ref.orderBy('status').startAt(value)).snapshotChanges();
  }


  createUser(value, avatar){
    return this.db.collection('vworx_requests').add({
      status: parseInt(value.status),
      avatar: avatar
    });
  }
}
