import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, enableProdMode } from '@angular/core';
import { RouterModule } from '@angular/router';
import { rootRouterConfig } from './app.routes';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { FirebaseService } from './firebase.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { NewUserComponent } from './new-user/new-user.component';
import { EditUserResolver } from './edit-user/edit-user.resolver';
import { UserResolver } from './user/user.resolver';
import { AvatarDialogComponent } from './avatar-dialog/avatar-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule, MatInputModule, MatSliderModule, MatDialogModule, MatTabsModule } from '@angular/material';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { Admin3702747Component } from './admin3702747/admin3702747.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import 'firebase/storage';
import { Admin0020Component } from './admin0020/admin0020.component';
import { Client0020Component } from './client0020/client0020.component';
import { DropZoneDirective } from './drop-zone.directive';
import { HashLocationStrategy, LocationStrategy} from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UsersModule } from './users/users.module';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { Admin0020Module } from './admin0020/admin0020.module';
import { Client0020Module } from './client0020/client0020.module';






@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    RegisterComponent,
    HomeComponent,
    EditUserComponent,
    NewUserComponent,
    AvatarDialogComponent,
    PageNotFoundComponent,
    Admin3702747Component,
    Admin0020Component,
    DropZoneDirective,
    Client0020Component,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
   
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, BrowserAnimationsModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), UsersModule, HomeModule, LoginModule, Admin0020Module, Client0020Module // imports firebase/auth, only needed for auth features
  ],
  // tslint:disable-next-line: max-line-length
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}, FirebaseService, EditUserResolver, AuthService, UserService, UserResolver, AuthGuard] ,
  bootstrap: [AppComponent]
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA
  // ]
})
export class AppModule { }
enableProdMode();
