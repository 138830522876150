
<!-- <div class="search-box-area">
    <div id="search" class="fade">
      <div (click)="logout()" class="round-o-3 round-color-e"><span class="glyphicon glyphicon-off" aria-hidden="true"></span></div>

        <ul id="searchbox">
            Menu
            <li>VMS Dashboard</li>
            <li>Support</li>
            <li>Log Out</li>
        </ul>
    </div>
</div> -->

    <!-- Content -->
    <div class="col-md-12" style="margin-bottom: -30px !important;">
        <div class="content">
            <div class="col-md-12">
                <div class="row">
                    <div class="brand"> <a class="navbar-brand" href="#">
                      <img alt="Brand" src="../../assets/img/logo.png">
                      </a></div>
                      <div (click)="logout()" class="round-o-3 round-color-e"><span class="glyphicon glyphicon-off" aria-hidden="true"></span></div>
              
                    <br>
                    <br>
                    <br>
                    <div class="col-md-12" style="margin-top: 50px;">
                    <!-- <h1>{{user.name}}'s</h1>
                    <h1>Call Out Details</h1> -->

                    <br><br>
                </div>
                </div>
            </div>


    <!-- Request Card -->
    <div class="col-md-6 col-sm-12">
        <div class="row card-max">
            <div class="card-content">
                <br>
                <h1 class="light">{{item.storeName}}</h1>
                <p class="light address">{{item.storeAddress}}</p>
                <!-- <br> -->
                <div>
                <h3 class="light">REPORTED BY</h3>
                <p class="light">{{item.userEmail}}<br><span class="address">{{item.datePosted | date : "d MMM, y"}}</span></p>
                <hr>
                <h3 class="light">BRAND</h3>
                <p  class="light">{{item.manufacturer}} {{item.brand}}</p>
                <h3 class="light">ISSUES</h3>
                <ul class="light" style="list-style: none; padding: 0;">
                  <div class="col-md-12" style="padding: 0;">
                  <li><p>{{item.reportedIssues[0]}}</p></li>
                  <li><p>{{item.reportedIssues[1]}}</p></li>
                  <li><p>{{item.reportedIssues[3]}}</p></li>
                  <li><p>{{item.reportedIssues[4]}}</p></li>
                </div>

                <div class="col-md-12" style="padding: 0;">
                  <li><p>{{item.reportedIssues[5]}}</p></li>
                  <li><p>{{item.reportedIssues[6]}}</p></li>
                  <li><p>{{item.reportedIssues[7]}}</p></li>
                  <li><p>{{item.reportedIssues[8]}}</p></li>
                </div>
                </ul>
              </div>
                <hr>
            <div class="col-md-6" style="padding: 0;">
                <h3 class="light">STATUS</h3>
                <p class="light">{{item.status}}</p>
            </div>
            <div class="col-md-6">
                <h3 class="light">PO NUMBER</h3>
                <p  class="light">{{item.poNumber}}</p>
            </div>
            <div class="col-md-6" style="padding: 0;">
                <h3 class="light">CE NUMBER</h3>
            <p class="light">{{item.ceAmount}}</p>
            </div>
            <div class="col-md-6">
                <h3 class="light">CE AMOUNT</h3>
                <h2 class="light">{{item.ceAmount | currency:'ZAR' | slice:2}}</h2>
                <br><br>
            </div>
               
            </div>
        </div>
        <form class="edit-form" [formGroup]="exampleForm" novalidate (ngSubmit)="onSubmit(exampleForm.value)">
        <!-- Form -->
        <div class="form-group"> 
        <div class="row card-max" style="margin: 15px 0;">
            <div class="card-content-min">
                <div class="col-md-6 col-sm-12">
                    <h3 class="light">PO NUMBER</h3>
                    <input type="text" class="edit-ce" formControlName="poNumber">
                </div>

                <!-- <div class="vr"></div> -->

                <div class="col-md-6 col-sm-12">
                    <h3 class="light">APPROVE/ REJECT CE</h3>
                    <input style="display: none"class="form-control" formControlName="status"> 
                    <input name="status" type="radio" value="Approved" formControlName="status"/> <span class="light">&nbsp;	Approve &nbsp;	</span>
                    <input name="status" type="radio" value="Rejected" formControlName="status"/> <span class="light">&nbsp; Reject</span>
                  <!-- <br>  <input name="status" type="radio" value="Onhold" formControlName="status"/> <span class="light">	&nbsp; Hold &nbsp; &nbsp; &nbsp; &nbsp;</span> -->
                  <div *ngIf="user.name  == 'Visible Worx'"><input name="status" type="radio" value="Completed" formControlName="status"/> <span class="light">	&nbsp; Completed</span></div>
                </div>
            </div>
        </div>
        

        <button class="col-md-5 submit" type="submit" [disabled]="!exampleForm.valid">Submit</button>
        <!-- <button (click)="deleteUser()" class="col-md-5 submit" type="submit" [disabled]="!exampleForm.valid">Delete</button>
      -->
        <br>
    </div>
  </form>
  </div>
  
    <!-- //Request Card -->

    <!-- Image Card -->
    <div class="col-md-6 col-sm-12">
        <div class="row card-max" style="margin-bottom: 50px;">
            <div class="card-content">
                <hr>
                <div class="thumbnail">
                    <img class="responsive media-object" [src]="item.photos[0]" onerror="this.style.display='none'">
            </div>
            <hr>

            <div class="thumbnail">
              <img class="responsive media-object" [src]="item.photos[1]" onerror="this.style.display='none'">
        </div>
        <hr>

        <div class="thumbnail">
          <img class="responsive media-object" [src]="item.photos[2]" onerror="this.style.display='none'">
    </div>
    <hr>

    <div class="thumbnail">
      <img class="responsive media-object" [src]="item.photos[3]" onerror="this.style.display='none'">
</div>

<hr>

<div class="thumbnail">
  <img class="responsive media-object" [src]="item.photos[4]" onerror="this.style.display='none'">
</div>

<hr>

<div class="thumbnail">
  <img class="responsive media-object" [src]="item.photos[5]" onerror="this.style.display='none'">
</div>

<hr>

<div class="thumbnail">
  <img class="responsive media-object" [src]="item.photos[6]" onerror="this.style.display='none'">
</div>
<hr>

    