import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../firebase.service';
import { Router, Params, ActivatedRoute  } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { FirebaseUserModel } from '../core/user.model';
import { Location } from '@angular/common';
import { UserResolver } from '../user/user.resolver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;
  ageValue = 0;
  searchValue = '';
  items: Array<any>;
  age_filtered_items: Array<any>;
  name_filtered_items: Array<any>;

  constructor(
    public firebaseService: FirebaseService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    public userService: UserService,
    public authService: AuthService,
    public MatPaginatorModule: MatPaginatorModule,
    // public afAuth: AngularFireAuth
    // tslint:disable-next-line: no-shadowed-variable
    public UserResolver: UserResolver,

  ) { }


  ngOnInit(): void {
    this.getData();
    this.route.data.subscribe(routeData => {
      const data = routeData.data;
      if (data) {
        this.user = data;
        // tslint:disable-next-line: no-unused-expression
        // this.user.name;
      }
    });
  }
  // createForm(name) {
  //   this.profileForm = this.fb.group({
  //     name: [name, Validators.required ]
  //   });
  // }

  save(value){
    this.userService.updateCurrentUser(value)
    .then(res => {
      console.log(res);
    }, err => console.log(err));
  }


  getData(){
    this.firebaseService.getUsers()
    .subscribe(result => {
      this.items = result;
      // this.age_filtered_items = result;
      // this.name_filtered_items = result;
    });
  }

  // tslint:disable-next-line: one-line
  viewDetails(item){
    this.router.navigate(['/client0020/' + item.payload.doc.id]);
  }
  viewAdminDetails(item){
    this.router.navigate(['/admin0020/' + item.payload.doc.id]);
  }
  capitalizeFirstLetter(value){
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  searchByName(){
    const value = this.searchValue.toLowerCase();
    this.firebaseService.searchUsers(value)
    .subscribe(result => {
      this.name_filtered_items = result;
      this.items = this.combineLists(result, this.age_filtered_items);
    });
  }

  rangeChange(event){
    this.firebaseService.searchUsersByAge(event.value)
    .subscribe(result => {
      this.age_filtered_items = result;
      this.items = this.combineLists(result, this.name_filtered_items);
    });
  }

  combineLists(a, b){
    const result = [];

    a.filter(x => {
      return b.filter(x2 => {
        if (x2.payload.doc.id == x.payload.doc.id){
          result.push(x2);
        }
      });
    });
    return result;
  }
  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.location.back();
    }, (error) => {
      console.log('Logout error', error);
    });
  }
}
