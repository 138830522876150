<div class="main-content" style="margin-top: -30px;">

  <!-- Content -->
   <div class="col-md-12" style="margin-bottom: -30px !important; ">
     <div class="content">
         <div class="col-md-12">
             <div class="row">
                 <div class="brand"> <a class="navbar-brand" href="#">
                     <img alt="Brand" src="../../assets/img/logo5x.png">
                   </a></div>
 
                   
                   
                   <div alt="Log Out" (click)="logout()" class="round-o-3 round-color-e"><span class="glyphicon glyphicon-off" aria-hidden="true"></span></div>
                 <br>
                 <br>
                 <br>
                 <div class="col-md-12" style="margin-top: 65px;">
                 <h1>{{user.name}}</h1>
                 <h1>Overall Summary</h1>
                 <br><br>
             </div>
             </div>
         </div>
 
 
  <span *ngIf="user.name  !== 'Visible Worx'">
 <!-- Request Card -->
 <div class="col-md-3 tablink active" onload="btnGetCount()" value="Count Rows">
 <a  href="#requets" data-toggle="tab">
     <div class="row card">
         <div class="card-content">
             <div class="round round-color-a"><span class="glyphicon glyphicon-log-in" aria-hidden="true"></span></div>
             <span class="card-heading">
             <h3 class="light">MAINTENANCE</h3>
             <h3 class="light">OVERVIEW</h3>
             </span>
         </div>
     </div>
 </a>
 </div>
 <!-- //Request Card -->

 
  <!-- Approved Card -->
  <div class="col-md-3 tablink" *ngIf="user.name  == 'Visible Worx'">
    <a  href="#pending" data-toggle="tab">
    <div class="row card">
        <div class="card-content">
            <div class="round round-color-f"><span class="glyphicon glyphicon-glyphicon-refresh" aria-hidden="true"></span></div>
            <span class="card-heading">
            <h3 class="light">PENDING</h3>
            <h3 class="light">MAINTENANCE</h3>
            </span>
        </div>
    </div>
    </a>
</div>
<!-- //Approved Card -->

 
 <!-- Approved Card -->
 <div class="col-md-3 tablink">
     <a  href="#approved" data-toggle="tab">
     <div class="row card">
         <div class="card-content">
             <div class="round round-color-c"><span class="glyphicon glyphicon-check" aria-hidden="true"></span></div>
             <span class="card-heading">
             <h3 class="light">APPROVED</h3>
             <h3 class="light">MAINTENANCE</h3>
             </span>
         </div>
     </div>
     </a>
 </div>
 <!-- //Approved Card -->
 
 <!-- Completed Card -->
 <div class="col-md-3 tablink" >
     <a  href="#completed" data-toggle="tab">
     <div class="row card">
         <div class="card-content">
             <div class="round round-color-b"><span class="glyphicon glyphicon-edit" aria-hidden="true"></span></div>
             <span class="card-heading">
             <h3 class="light">COMPLETED</h3>
             <h3 class="light">MAINTENANCE</h3>
             </span>
         </div>
     </div>
     </a>
 </div>
 <!-- Completed Card -->
 
 <!-- Rejected Card -->
 <div class="col-md-3 tablink">
     <a  href="#rejected" data-toggle="tab">
     <div class="row card">
         <div class="card-content">
             <div class="round round-color-d"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></div>
             <span class="card-heading">
             <h3 class="light">REJECTED</h3>
             <h3 class="light">MAINTENANCE</h3>
           </span>
         </div>
     </div>
     </a>
 </div>
 
 <!-- //Rejected Card -->
 </span>

 <span *ngIf="user.name  == 'Visible Worx'">
    <!-- Request Card -->
 <div class="col-md-2 tablink active" onload="btnGetCount()" value="Count Rows">
  <a  href="#requets" data-toggle="tab">
      <div class="row card-admin">
          <div class="card-content">
              <div class="round-admin round-color-a"><span class="glyphicon glyphicon-log-in" aria-hidden="true"></span></div>
              <span class="card-heading">
              <h3 class="light">MAINTENANCE</h3>
              <h3 class="light">OVERVIEW</h3>
              </span>
          </div>
      </div>
  </a>
  </div>
  <!-- //Request Card -->

 
   <!-- Approved Card -->
   <div class="col-md-2 tablink" *ngIf="user.name  == 'Visible Worx'">
     <a  href="#pending" data-toggle="tab">
     <div class="row card-admin">
         <div class="card-content">
             <div class="round-admin round-color-f"><span class="glyphicon glyphicon-refresh" aria-hidden="true"></span></div>
             <span class="card-heading">
             <h3 class="light">PENDING</h3>
             <h3 class="light">MAINTENANCE</h3>
             </span>
         </div>
     </div>
     </a>
 </div>
 <!-- //Approved Card -->
 
  
  <!-- Approved Card -->
  <div class="col-md-2 tablink">
      <a  href="#approved" data-toggle="tab">
      <div class="row card-admin">
          <div class="card-content">
              <div class="round-admin round-color-c"><span class="glyphicon glyphicon-check" aria-hidden="true"></span></div>
              <span class="card-heading">
              <h3 class="light">APPROVED</h3>
              <h3 class="light">MAINTENANCE</h3>
              </span>
          </div>
      </div>
      </a>
  </div>
  <!-- //Approved Card -->
  
  <!-- Completed Card -->
  <div class="col-md-2 tablink" >
      <a  href="#completed" data-toggle="tab">
      <div class="row card-admin">
          <div class="card-content">
              <div class="round-admin round-color-b"><span class="glyphicon glyphicon-edit" aria-hidden="true"></span></div>
              <span class="card-heading">
              <h3 class="light">COMPLETED</h3>
              <h3 class="light">MAINTENANCE</h3>
              </span>
          </div>
      </div>
      </a>
  </div>
  <!-- Completed Card -->
  
  <!-- Rejected Card -->
  <div class="col-md-2 tablink">
      <a  href="#rejected" data-toggle="tab">
      <div class="row card-admin">
          <div class="card-content">
              <div class="round-admin round-color-d"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></div>
              <span class="card-heading">
              <h3 class="light">REJECTED</h3>
              <h3 class="light">MAINTENANCE</h3>
            </span>
          </div>
      </div>
      </a>
  </div>
  
  <!-- //Rejected Card -->
 </span>

 
         <div class="col-md-12">
             <div class="row">
                 <br>
                 
                 <br>
             </div>
         </div>
 <!-- Table -->
 <div class="col-md-12">
     <div class="row card-max" style="margin-bottom: 50px;">
         <div class="card-content">
             <div class="tab-content">
                 <!-- Requests -->
                 <div class="tab-pane active" id="requets">
                   <span>
                     <h1 class="light">Maintenance Overview</h1> 
                   </span>
      
             
                     <button *ngIf="user.name  == 'Visible Worx'" onclick="exportToExcel('tblexportDatavw', 'Overview Report')"  style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                     <button *ngIf="user.name  !== 'Visible Worx'" onclick="exportToExcel('tblexportData', 'Overview Report')" style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                         <table *ngIf="user.name  !== 'Visible Worx'" id="tblexportData" class="table table-bordered table-striped">
                           
                           <thead>
                             <tr>
                                 <th><b>Date</b></th>
                                 <th><b>Store Name</b></th>
                                 <th><b>Manufacturer/ Brand</b></th>
                                 <th><b>Issues</b></th>
                                 <th><b>Priority</b></th>
                                 <th><b>Status</b></th>
                                 <th><b>PO Number</b></th>
                                 <th><b>Amount</b></th>
                             
                             </tr>
                             </thead>
                             <tbody>
                               <ng-container *ngFor="let item of items">
                               <tr *ngIf="user.name  == item.payload.doc.data().manufacturer" (click)="viewDetails(item)">
                                 <td>{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                 <td>{{item.payload.doc.data().storeName}}</td>
                                 <td>{{item.payload.doc.data().manufacturer}} {{item.payload.doc.data().brand}}</td>
                                 <td style="width: 200px !important;">
                                   {{item.payload.doc.data().reportedIssues[0]}}
                                   {{item.payload.doc.data().reportedIssues[1]}}
                                   {{item.payload.doc.data().reportedIssues[2]}}
                                   {{item.payload.doc.data().reportedIssues[3]}}
                                   {{item.payload.doc.data().reportedIssues[4]}}
                                   {{item.payload.doc.data().reportedIssues[5]}}
                                   {{item.payload.doc.data().reportedIssues[6]}}
                                   {{item.payload.doc.data().reportedIssues[7]}}
                                   {{item.payload.doc.data().reportedIssues[8]}}
                                   {{item.payload.doc.data().reportedIssues[9]}}
                                 </td>
                                 <td ><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                 <td ><span *ngIf="item.payload.doc.data().status !== 'null'">{{item.payload.doc.data().status}}</span></td>
                                 <td >{{item.payload.doc.data().poNumber}}</td>
                                 <td>{{item.payload.doc.data().ceAmount}}</td>
                                   </tr> 
                                 </ng-container>
                                 </table>
 
 
 
                                   <table *ngIf="user.name  == 'Visible Worx'" id="tblexportDatavw" class="table table-bordered table-striped">
                                     <thead>
                                       <tr>
                                           <th><b>Date</b></th>
                                           <th><b>Store Name</b></th>
                                           <th><b>Manufacturer/ Brand</b></th>
                                           <th><b>Issues</b></th>
                                           <th><b>Priority</b></th>
                                           <th><b>Status</b></th>
                                           <th style="display: none;">User Email</th>
                                           <th style="display: none;">Completion Date</th>
                                           
                                           <th><b>PO Number</b></th>
                                           <th><b>Amount</b></th>
                                       
                                       </tr>
                                       </thead>
                                       <tbody>
                                   <tr *ngFor="let item of items" (click)="viewAdminDetails(item)" >
                                     <td *ngIf="user.name  == 'Visible Worx'">{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                     <td *ngIf="user.name  == 'Visible Worx'">{{item.payload.doc.data().storeName}}</td>
                                     <td *ngIf="user.name  == 'Visible Worx'">{{item.payload.doc.data().manufacturer}} {{item.payload.doc.data().brand}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx'" style="width: 200px !important;">
                                       {{item.payload.doc.data().reportedIssues[0]}}
                                       {{item.payload.doc.data().reportedIssues[1]}}
                                       {{item.payload.doc.data().reportedIssues[2]}}
                                       {{item.payload.doc.data().reportedIssues[3]}}
                                       {{item.payload.doc.data().reportedIssues[4]}}
                                       {{item.payload.doc.data().reportedIssues[5]}}
                                       {{item.payload.doc.data().reportedIssues[6]}}
                                       {{item.payload.doc.data().reportedIssues[7]}}
                                       {{item.payload.doc.data().reportedIssues[8]}}
                                       {{item.payload.doc.data().reportedIssues[9]}}
                                     </td>
                                     <td *ngIf="user.name  == 'Visible Worx'"><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                     <td *ngIf="user.name  == 'Visible Worx'"><span *ngIf="item.payload.doc.data().status !== 'null'">{{item.payload.doc.data().status}}</span></td>
                                     <td style="display: none;">{{item.payload.doc.data().userEmail}}</td>
                                     <td style="display: none;">{{item.payload.doc.data().invoiceMonth}}</td>
                                     <td *ngIf="user.name  == 'Visible Worx'">{{item.payload.doc.data().poNumber}}</td>
                                     <td *ngIf="user.name  == 'Visible Worx'">{{item.payload.doc.data().ceAmount}}</td>
                                     </tr> 
                     </table>
                         
                 </div>
                 <!-- Requests -->
                 <!-- Approved -->
                 <div class="tab-pane"  id="approved">
                     <h1 class="light">Approved Maintenance</h1> 
                     <span>
                       <button *ngIf="user.name  == 'Visible Worx'" onclick="exportToExcel('tblexportData2vw', 'Approved Report')"  style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                     <button *ngIf="user.name  !== 'Visible Worx'" onclick="exportToExcel('tblexportData2', 'Approved Report')" style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                     </span>
                         <table *ngIf="user.name  !== 'Visible Worx'"  id="tblexportData2" class="table table-bordered table-striped">
                             <thead>
                               <tr>
                                 <th><b>Date</b></th>
                                 <th><b>Store Name</b></th>
                                 <th><b>Manufacturer/ Brand</b></th>
                                 <th><b>Issues</b></th>
                                 <th><b>Priority</b></th>
                                 <th><b>Status</b></th>
                                 <th><b>PO Number</b></th>
                                 <th><b>Amount</b></th>
                             
                             </tr>
                                 </thead>
                                 <tbody>
                                   <ng-container *ngFor="let item of items">
                                     <tr *ngIf="user.name  == item.payload.doc.data().manufacturer && item.payload.doc.data().status === 'Approved'" (click)="viewDetails(item)">
                                     <td>{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                     <td>{{item.payload.doc.data().storeName}}</td>
                                     <td>{{item.payload.doc.data().manufacturer}} {{item.payload.doc.data().brand}}</td>
                                       <td style="width: 200px !important;">
                                       {{item.payload.doc.data().reportedIssues[0]}}
                                       {{item.payload.doc.data().reportedIssues[1]}}
                                       {{item.payload.doc.data().reportedIssues[2]}}
                                       {{item.payload.doc.data().reportedIssues[3]}}
                                       {{item.payload.doc.data().reportedIssues[4]}}
                                       {{item.payload.doc.data().reportedIssues[5]}}
                                       {{item.payload.doc.data().reportedIssues[6]}}
                                       {{item.payload.doc.data().reportedIssues[7]}}
                                       {{item.payload.doc.data().reportedIssues[8]}}
                                       {{item.payload.doc.data().reportedIssues[9]}}
                                     </td>
                                     <td><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                     <td>{{item.payload.doc.data().status}}</td>
                                     <td>{{item.payload.doc.data().poNumber}}</td>
                                     <td>{{item.payload.doc.data().ceAmount}}</td>
                                     </tr> 
                                     </ng-container>
                                   </table>
 
 
                                   <table *ngIf="user.name  == 'Visible Worx'"  id="tblexportData2vw" class="table table-bordered table-striped">
                                     <thead>
                                       <tr>
                                         <th><b>Date</b></th>
                                         <th><b>Store Name</b></th>
                                         <th><b>Manufacturer/ Brand</b></th>
                                         <th><b>Issues</b></th>
                                         <th><b>Priority</b></th>
                                         <th><b>Status</b></th>
                                         <th><b>PO Number</b></th>
                                         <th><b>Amount</b></th>
                                         
                                     
                                     </tr>
                                         </thead>
                                         <tbody>
                                     <tr *ngFor="let item of items" (click)="viewDetails(item)">
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'">{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'">{{item.payload.doc.data().storeName}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'">{{item.payload.doc.data().manufacturer}} {{item.payload.doc.data().brand}}</td>
                                         <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'" style="width: 200px !important;">
                                         {{item.payload.doc.data().reportedIssues[0]}}
                                         {{item.payload.doc.data().reportedIssues[1]}}
                                         {{item.payload.doc.data().reportedIssues[2]}}
                                         {{item.payload.doc.data().reportedIssues[3]}}
                                         {{item.payload.doc.data().reportedIssues[4]}}
                                         {{item.payload.doc.data().reportedIssues[5]}}
                                         {{item.payload.doc.data().reportedIssues[6]}}
                                         {{item.payload.doc.data().reportedIssues[7]}}
                                         {{item.payload.doc.data().reportedIssues[8]}}
                                         {{item.payload.doc.data().reportedIssues[9]}}
                                       </td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'"><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'">{{item.payload.doc.data().status}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'">{{item.payload.doc.data().poNumber}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Approved'">{{item.payload.doc.data().ceAmount}}</td>
                                       </tr> 
                      
                                 <!-- </tbody> -->
                         </table>
                 </div>
                 <!-- Approved -->
 
 
                 
                 <!-- Pending -->
                 <div class="tab-pane" id="completed">
                     <h1 class="light">Completed Maintenance</h1> 
                     <span>
                       <button *ngIf="user.name  == 'Visible Worx'" onclick="exportToExcel('tblexportData3vw', 'Completed Report')"  style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                       <button *ngIf="user.name  !== 'Visible Worx'" onclick="exportToExcel('tblexportData3', 'Completed Report')" style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                     </span>
                         <table *ngIf="user.name  !== 'Visible Worx'" id="tblexportData3" class="table table-bordered table-striped">
                             <thead>
                               <tr>
                                 <th><b>Date</b></th>
                                 <th><b>Store Name</b></th>
                                 <th><b>Manufacturer/ Brand</b></th>
                                 <th><b>Issues</b></th>
                                 <th><b>Priority</b></th>
                                 <th><b>Status</b></th>
                                 <th><b>PO Number</b></th>
                                 <th><b>Amount</b></th>
                             
                             </tr>
                                 </thead>
                                 <tbody>
                                   <ng-container *ngFor="let item of items">
                                     <tr *ngIf="user.name  == item.payload.doc.data().manufacturer && item.payload.doc.data().status === 'Completed'" (click)="viewDetails(item)">
                                     <td>{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                     <td>{{item.payload.doc.data().storeName}}</td>
                                     <td>{{item.payload.doc.data().manufacturer}} {{item.payload.doc.data().brand}}</td>
                                     <td style="width: 200px !important;">
                                       {{item.payload.doc.data().reportedIssues[0]}}
                                       {{item.payload.doc.data().reportedIssues[1]}}
                                       {{item.payload.doc.data().reportedIssues[2]}}
                                       {{item.payload.doc.data().reportedIssues[3]}}
                                       {{item.payload.doc.data().reportedIssues[4]}}
                                       {{item.payload.doc.data().reportedIssues[5]}}
                                       {{item.payload.doc.data().reportedIssues[6]}}
                                       {{item.payload.doc.data().reportedIssues[7]}}
                                       {{item.payload.doc.data().reportedIssues[8]}}
                                       {{item.payload.doc.data().reportedIssues[9]}}
                                     </td>
                                     <td><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                     <td>{{item.payload.doc.data().status}}</td>
                                     <td>{{item.payload.doc.data().poNumber}}</td>
                                     <td>{{item.payload.doc.data().ceAmount}}</td>
                                     </tr> 
                                     </ng-container>
                                   </table>
                                   <table *ngIf="user.name  == 'Visible Worx'" id="tblexportData3vw" class="table table-bordered table-striped">
                                     <thead>
                                       <tr>
                                         <th><b>Date</b></th>
                                         <th><b>Store Name</b></th>
                                         <th><b>Manufacturer/ Brand</b></th>
                                         <th><b>Issues</b></th>
                                         <th><b>Priority</b></th>
                                         <th><b>Status</b></th>
                                         <th><b>PO Number</b></th>
                                         <th><b>Amount</b></th>
                                     
                                     </tr>
                                         </thead>
                                         <tbody>
 
                                     <tr *ngFor="let item of items" (click)="viewDetails(item)">
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'">{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'">{{item.payload.doc.data().storeName}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'">{{'Visible Worx'}} {{item.payload.doc.data().brand}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'" style="width: 200px !important;">
                                         {{item.payload.doc.data().reportedIssues[0]}}
                                         {{item.payload.doc.data().reportedIssues[1]}}
                                         {{item.payload.doc.data().reportedIssues[2]}}
                                         {{item.payload.doc.data().reportedIssues[3]}}
                                         {{item.payload.doc.data().reportedIssues[4]}}
                                         {{item.payload.doc.data().reportedIssues[5]}}
                                         {{item.payload.doc.data().reportedIssues[6]}}
                                         {{item.payload.doc.data().reportedIssues[7]}}
                                         {{item.payload.doc.data().reportedIssues[8]}}
                                         {{item.payload.doc.data().reportedIssues[9]}}
                                       </td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'"><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'">{{item.payload.doc.data().status}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'">{{item.payload.doc.data().poNumber}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Completed'">{{item.payload.doc.data().ceAmount}}</td>
                                       </tr> 
                         </table>
                 </div>
                 <!-- Pending -->
                 <div class="tab-pane" id="pending">
                  <h1 class="light">Pending Maintenance</h1> 
                  <span>
                    <button *ngIf="user.name  == 'Visible Worx'" onclick="exportToExcel('tblexportData5', 'Pending Report')"  style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                  </span>
                      <table *ngIf="user.name  == 'Visible Worx'" id="tblexportData4" class="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th><b>Date</b></th>
                              <th><b>Store Name</b></th>
                              <th><b>Manufacturer/ Brand</b></th>
                              <th><b>Issues</b></th>
                              <th><b>Priority</b></th>
                              <th><b>Status</b></th>
                              <!-- <th><b>PO Number</b></th>
                              <th><b>Amount</b></th> -->
                          
                          </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let item of items">
                                  <tr *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().ceAmount == ''" (click)="viewDetails(item)">
                                  <td *ngIf="item.payload.doc.data().status !== 'Rejected'">{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                  <td *ngIf="item.payload.doc.data().status !== 'Rejected'">{{item.payload.doc.data().storeName}}</td>
                                  <td *ngIf="item.payload.doc.data().status !== 'Rejected'">{{item.payload.doc.data().manufacturer}} {{item.payload.doc.data().brand}}</td>
                                  <td style="width: 200px !important;" *ngIf="item.payload.doc.data().status !== 'Rejected'">
                                    {{item.payload.doc.data().reportedIssues[0]}}
                                    {{item.payload.doc.data().reportedIssues[1]}}
                                    {{item.payload.doc.data().reportedIssues[2]}}
                                    {{item.payload.doc.data().reportedIssues[3]}}
                                    {{item.payload.doc.data().reportedIssues[4]}}
                                    {{item.payload.doc.data().reportedIssues[5]}}
                                    {{item.payload.doc.data().reportedIssues[6]}}
                                    {{item.payload.doc.data().reportedIssues[7]}}
                                    {{item.payload.doc.data().reportedIssues[8]}}
                                    {{item.payload.doc.data().reportedIssues[9]}}
                                  </td>
                                  <td *ngIf="item.payload.doc.data().status !== 'Rejected'"><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                  <td *ngIf="item.payload.doc.data().status !== 'Rejected'"><span *ngIf="item.payload.doc.data().status !== 'null'">{{item.payload.doc.data().status}}</span></td>
                                  <!-- <td>{{item.payload.doc.data().poNumber}}</td>
                                  <td>{{item.payload.doc.data().ceAmount}}</td> -->
                                  </tr> 
                                  </ng-container>
                                </table> 
              </div>
              <!-- Pending -->

                 <!-- Rejected -->
                 <div class="tab-pane" id="rejected">
                     <h1 class="light">Rejected Maintenance</h1> 
                     <span>
                       <button *ngIf="user.name  == 'Visible Worx'" onclick="exportToExcel('tblexportData4vw', 'Rejected Maintenance Report')"  style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                       <button *ngIf="user.name  !== 'Visible Worx'" onclick="exportToExcel('tblexportData4', 'Rejected Maintenance Report')" style="float: right; outline: 0;" type="button" class="btn btn-default">Download Report</button>
                     </span>
                         <table *ngIf="user.name  !== 'Visible Worx'" id="tblexportData4" class="table table-bordered table-striped">
                             <thead>
                               <tr>
                                 <th><b>Date</b></th>
                                 <th><b>Store Name</b></th>
                                 <th><b>Manufacturer/ Brand</b></th>
                                 <th><b>Issues</b></th>
                                 <th><b>Status</b></th>
                                 <!-- <th><b>PO Number</b></th> -->
                                 <th><b>Amount</b></th>
                             
                             </tr>
                                 </thead>
                                 <tbody>
                                   <ng-container *ngFor="let item of items">
                                     <tr *ngIf="user.name  == item.payload.doc.data().manufacturer && item.payload.doc.data().status === 'Rejected'" (click)="viewDetails(item)">
                                     <td>{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                     <td>{{item.payload.doc.data().storeName}}</td>
                                     <td>{{item.payload.doc.data().manufacturer}} {{item.payload.doc.data().brand}}</td>
                                     <td style="width: 200px !important;">
                                       {{item.payload.doc.data().reportedIssues[0]}}
                                       {{item.payload.doc.data().reportedIssues[1]}}
                                       {{item.payload.doc.data().reportedIssues[2]}}
                                       {{item.payload.doc.data().reportedIssues[3]}}
                                       {{item.payload.doc.data().reportedIssues[4]}}
                                       {{item.payload.doc.data().reportedIssues[5]}}
                                       {{item.payload.doc.data().reportedIssues[6]}}
                                       {{item.payload.doc.data().reportedIssues[7]}}
                                       {{item.payload.doc.data().reportedIssues[8]}}
                                       {{item.payload.doc.data().reportedIssues[9]}}
                                     </td>
                                     <td><span *ngIf="item.payload.doc.data().priority == 'High Priority'" class="label label-danger">{{item.payload.doc.data().priority}}</span></td>
                                     <td>{{item.payload.doc.data().status}}</td>
                                     <!-- <td>{{item.payload.doc.data().poNumber}}</td> -->
                                     <td>{{item.payload.doc.data().ceAmount}}</td>
                                     </tr> 
                                     </ng-container>
                                   </table>
 
                                     <table *ngIf="user.name  == 'Visible Worx'" id="tblexportData4vw" class="table table-bordered table-striped">
                                       <thead>
                                         <tr>
                                           <th><b>Date</b></th>
                                           <th><b>Store Name</b></th>
                                           <th><b>Manufacturer/ Brand</b></th>
                                           <th><b>Issues</b></th>
                                           <th><b>Status</b></th>
                                           <!-- <th><b>PO Number</b></th> -->
                                           <th><b>Amount</b></th>
                                       
                                       </tr>
                                           </thead>
                                           <tbody>
                                     <tr *ngFor="let item of items" (click)="viewDetails(item)">
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Rejected'">{{item.payload.doc.data().datePosted | date : "dd-MM-y"}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Rejected'">{{item.payload.doc.data().storeName}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Rejected'">{{'Visible Worx'}} {{item.payload.doc.data().brand}}</td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Rejected'" style="width: 200px !important;">
                                         {{item.payload.doc.data().reportedIssues[0]}}
                                         {{item.payload.doc.data().reportedIssues[1]}}
                                         {{item.payload.doc.data().reportedIssues[2]}}
                                         {{item.payload.doc.data().reportedIssues[3]}}
                                         {{item.payload.doc.data().reportedIssues[4]}}
                                         {{item.payload.doc.data().reportedIssues[5]}}
                                         {{item.payload.doc.data().reportedIssues[6]}}
                                         {{item.payload.doc.data().reportedIssues[7]}}
                                         {{item.payload.doc.data().reportedIssues[8]}}
                                         {{item.payload.doc.data().reportedIssues[9]}}
                                       </td>
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Rejected'">{{item.payload.doc.data().status}}</td>
                                       <!-- <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Rejected'">{{item.payload.doc.data().poNumber}}</td> -->
                                       <td *ngIf="user.name  == 'Visible Worx' && item.payload.doc.data().status === 'Rejected'">{{item.payload.doc.data().ceAmount}}</td>
                                       </tr> 
                         </table>
                       
                 </div>
                 <!-- Rejected -->
             </div>
             
         </div>
 </div>