// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB_ZTwR0Q67wpG92fSrVHXc_0q72Di24Pk',
    authDomain: 'visibleapp-79c8a.firebaseapp.com',
    databaseURL: 'https://visibleapp-79c8a.firebaseio.com',
    projectId: 'visibleapp-79c8a',
    storageBucket: 'visibleapp-79c8a.appspot.com',
    messagingSenderId: '766199267631'
  }
};
