import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Client0020RoutingModule } from './client0020-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Client0020RoutingModule
  ]
})
export class Client0020Module { }
